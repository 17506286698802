* { 
	padding:0; 
	margin:0;	
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
	min-height:100%;
}

body {
	font-family:  'Open Sans', Arial, sans-serif;
	font-display: swap;
    padding-bottom:5px;
	background-color: floralwhite;
    color:#222;
}

h2 {
	padding: 10px;
	font-size: 26px;
	margin-top: 20px;
}

h4 {
	text-align: center;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Open Sans Regular'), 
		local('OpenSans-Regular'), 
		url('/fonts/OpenSans-Regular.woff') format('woff'),
		url('/fonts/OpenSans-Regular.ttf') format('truetype');
}

button, #download {
	box-sizing: border-box;
	font-family: Arial, Arial, Helvetica, sans-serif;
	padding: 4px;
	width: 150px;
	font-size: 15px;
	font-weight: bold;
	border: solid 1px #999;
	border-radius: 4px;
	display: inline-block;
	margin: 2px;
	cursor: pointer;
	background: white;
	border-bottom-width: 2px;
	color: #333;
	text-decoration: none;
}

:focus {
	outline:none;
	text-decoration:underline !important;
}

button:hover, #download:hover {
	background: #fcfcfc;
}

#image-url {
	width: 1px;
	height: 1px;
	background-color: transparent;
	color: transparent;
	box-sizing: border-box;
	border-style: none;
}

button:active {
	position: relative;
	top: 1px;
	border-bottom-width: 1px;
	box-shadow: none;
}

#wrapper {
	max-width: 700px;
	margin: auto;
}

p {
	padding: 10px;
}

#control-buttons, #current-url {
	margin-top: 6px;
	text-align: center;
}

#version-info {
	margin-top: 30px;
	font-size: 12px;
	text-align: center;
}

#chessboard {
	display: block;
	margin:auto;
	max-width: 418px;
	max-height: 418px;
	cursor:pointer;
	margin-bottom: 10px;
}

#social {
	text-align: center;
}

#copy-url {
	position: relative;
}
#copy-url span {
	position: absolute;
	display: block;
	background: #777;
	color: white;
	padding: 2px 5px;
	border-radius: 5px;
	font-size: 12px;
	bottom: -24px;
	left: 37px;
	display: none;
}

#last-move-div {
	padding: 10px;
	text-align: center;
}

#last-move {
	text-decoration: none !important;
	padding:2px;
	font-size: 14px;
	border-width: 1px;
	border-radius: 4px;
	width: 70px;
}


img {
	cursor: pointer;
	box-sizing: border-box;
	border: solid 2px transparent;
}

img.selected {
	background: #ddd;
	border-radius: 5px;
	border: solid 2px #bbb;
}

img:hover {
	cursor:pointer;
}

#select-pieces div {
	text-align: center;
}

h1, h2, h3 {
	text-align: center;
	color: darkblue;
}

h1 {
	font-size: 36px;
	user-select: none;
	padding: 15px;
}

p {
	font-size: 16px;
	line-height: 25px;
}

a {
	word-break: break-word;
}

p span {
	word-break: break-word;
	font-family: 'Courier New', Courier, monospace;
}

/* Mobile resizing... */

@media (max-width:500px) {
	h1 {
		font-size: 18px;
		padding: 10px;
	}

	h4, #last-move-div {
		font-size: 13px;
	}

	#black-pieces img, #white-pieces img {
		width: 40px;
		height: 40px;
	}

	#chessboard {
		max-width: 86%;
	}
}